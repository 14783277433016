<template>
    <div class="history-fail-list fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">分销失败数据</span>
        </div>

        <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="importCardId"
                    label="分销表编号"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="电话"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="iccidMark"
                    label="ICCID号"
                    min-width="20%"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="remark"
                    label="错误描述"
                    min-width="25%"
                    >
                </el-table-column>
                
                <el-table-column
                    label="操作"
                    min-width="15%"
                    >
                    <template slot-scope="scope">
                        <el-button size="mini" class="themed-button" type="info" @click="handleDetail(scope.row.id)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>

        <el-dialog title="分销卡失败详情" :visible.sync="dialogFailDetailVisible" width="40%" :destroy-on-close='true'>
            <el-form :model="detailForm"  ref="detailForm">
                <el-form-item label="分销表编号" :label-width="formLabelWidth">
                <el-input v-model="detailForm.importCardId" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="电话" :label-width="formLabelWidth">
                <el-input v-model="detailForm.phone" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="ICCID号" :label-width="formLabelWidth">
                <el-input v-model="detailForm.iccidMark" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="错误描述" :label-width="formLabelWidth">
                <el-input type="textarea" autosize v-model="detailForm.remark" disabled></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="themed-button" type="primary" @click="dialogFailDetailVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>


</template>

<script>
import resize from '@/mixins/resize.js'
import { distributeFail,distributeFailDetail } from '@/api/history.js'
export default {
    name:'networkCardFailList',
    mixins:[resize],
    data(){
        return {
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
            dialogFailDetailVisible:false,
            formLabelWidth:'100px',
            detailForm:{}
        }
    },
    created(){
        this.importCardId = this.$route.query.id
        console.log(this.importCardId)
        this.getDistributeFailList()
    },
    methods:{
        getDistributeFailList(){
            let params = {
                importCardId:this.importCardId,
                pageSize:this.pageSize,
                pageNo:this.page
            }
            distributeFail(params).then(res=>{
                this.tableData = res.rows
                this.total=res.count
            })

        },
        onPageChange(page){
            this.page = page
            this.getDistributeFailList()
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getDistributeFailList()
        },
        handleDetail(id){
            this.dialogFailDetailVisible = true
            distributeFailDetail({id}).then(res=>{
                this.detailForm = res.entity
            })
        },

    }
}
</script>